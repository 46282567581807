<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    v-divider
  div.col-sm-12.col-md-12.col-lg-6.col-xl-4
    OriginalDoc(v-if="isCheckOriginDocument" :documentInfo="documentEducation")
  v-divider(vertical)
  div.col-sm-12.col-md-12.col-lg-6.col-xl-4
    TranslationDoc(v-if="isCheckTranslationDocument" :documentInfo="documentEducation")
    v-divider
    div(v-if="documentEducation.translation_company && translationCompany").col-sm-12.col-md-6
      +data-info('original.translation_company','translationCompany.find(translator => translator.id === documentEducation.translation_company).name')
    div(v-if="documentEducation.language && languages.length").col-sm-12.col-md-6
      +data-info('original.languageTranslation','languages.find(lang => lang.id === documentEducation.language)[nameLang]')
  v-divider(vertical)
  div.col-sm-12.col-md-12.col-lg-6.col-xl-4
    NostrificationDoc(:documentInfo="documentEducation" :number_document="number_document")
  div.col-12
    v-divider
  div(v-if="false").row
    div.col-sm-12.col-md-3.col-lg-2.col-xl-2.ma-2
      v-btn(v-if="documentEducation.is_translated" color="primary" @click="saveDocument('translationDocument', 'wotermark')" :loading="isLoading.translationDocument.wotermark").wordBreak {{$t('saveSignDocumetTranslate')}}
    div.col-sm-12.col-md-3.col-lg-2.col-xl-2.ma-2
      v-btn(v-if="documentEducation.is_translated" color="primary" @click="saveDocument('translationDocument', 'archive')" :loading="isLoading.translationDocument.archive").wordBreak {{$t('saveSignDocumetTranslateArchive')}}
    div.col-sm-12.col-md-3.col-lg-2.col-xl-2.ma-2
      v-btn(v-if="documentEducation.status_document === STATUSES_DOCUMENT_CONSTANTS.documentCreated" color="primary" @click="saveDocument('monDocument', 'wotermark')" :loading="isLoading.monDocument.wotermark").wordBreak {{$t('saveSignDocumetMon')}}
    div.col-sm-12.col-md-3.col-lg-2.col-xl-2.ma-2
      v-btn(v-if="documentEducation.status_document === STATUSES_DOCUMENT_CONSTANTS.documentCreated" color="primary" @click="saveDocument('monDocument', 'archive')" :loading="isLoading.monDocument.archive").wordBreak {{$t('saveSignDocumetMonArchive')}}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getDateFormat } from '@/mixins/main'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  components: {
    OriginalDoc: () => import('./Original.vue'),
    TranslationDoc: () => import('./Translation.vue'),
    NostrificationDoc: () => import('./Nostrification.vue')
  },
  props: {
    documentEducation: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      STATUSES_DOCUMENT_CONSTANTS,
      number_document: '',
      isLoading: {
        translationDocument: {
          archive: false,
          wotermark: false
        },
        monDocument: {
          archive: false,
          wotermark: false
        }
      }
    }
  },
  watch: {
    documentEducation: {
      handler () {},
      deep: true
    }
  },
  computed: {
    ...mapState({
      nameLang: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      translationCompany: (state) => state.directory.translators.results,
      languages: (state) => state.directory.languages
    }),
    ...mapGetters(['getDirectoryObject']),
    isCheckTranslationDocument () {
      let translationArray = []
      Object.entries(this.documentEducation).forEach(([key, value]) => {
        if (key.includes('ukr') && value) translationArray.push(key)
      })
      return translationArray.length
    },
    isCheckOriginDocument () {
      let originArray = []
      Object.entries(this.documentEducation).forEach(([key, value]) => {
        if (key.includes('original') && !!value) originArray.push(key)
      })
      return originArray.length
    }
  },
  mounted () {
    this.documentEducation.education_document && this.getDocument()
  },
  methods: {
    ...mapActions(['getEducationDocumentByID', 'saveDocumentNostrification']),
    async getDocument () {
      const response = await this.getEducationDocumentByID({ id: this.documentEducation.sailor.id, documentID: this.documentEducation.education_document })
      this.number_document = response.data.number_document
    },
    async saveDocument (typeDocument, typeFile) {
      this.isLoading[typeDocument][typeFile] = true
      const { id } = this.documentEducation
      let contentType = typeFile === 'archive' ? 'application/zip' : 'application/pdf'
      let fileName = typeDocument === 'mesDocument' ? `Nostrification_document_${this.documentEducation.number_document}` : `Translation_document_${this.documentEducation.number_document}`
      await this.saveDocumentNostrification({ id, typeDocument, typeFile, contentType, fileName })
      this.isLoading[typeDocument][typeFile] = false
    }
  }
}
</script>
<style lang="sass">
.wordBreak
  word-break: break-word !important
  width: 100% !important
  white-space: break-spaces !important
  height: 100% !important
.v-btn__content
  width: 100% !important
@media screan and (min-width:1920px)
  .v-btn__content
    font-size: 12px

</style>
